import React from "react";
import Root from "../components/Root";
import SEO from "../components/seo";
import ContactPage from "../components/Contact/ContactPage";

const Contact = () => (
  <Root>
    <SEO title="Contact" 
      description="Contact WhereToMine via email or social media in order to add a new pool or coin." />
    <ContactPage />
  </Root>
);

export default Contact;
