import React from "react";
import { Link } from "gatsby-theme-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
  },
  texts: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      margin: "40px auto",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "80px auto",
    },
  },
  header: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  description: {
    fontSize: "1rem",
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    transition: "color .1s ease-out",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  button: {
    marginTop: "80px",
    fontWeight: "700",
    textTransform: "initial",
    padding: "12px 28px",
    borderRadius: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
    },
  },
}));

const AboutPage = props => {
  const classes = useStyles(props);

  return (
    <div>
      <Container maxWidth="lg">
        <div className={classes.texts}>
          <Typography className={classes.header} variant="h1">
            Contact Information
          </Typography>
          <Typography className={classes.description} variant="subtitle1">
            contact@wheretomine.io
          </Typography>
        </div>
        <div className={classes.texts}>
          <Typography className={classes.header} variant="h1">
            Social Media
          </Typography>
          <Typography className={classes.description} variant="subtitle1">
            Twitter:{" "}
            <a href="https://twitter.com/wheretomineio" target="_blank" rel="noopener noreferrer">
              https://twitter.com/wheretomineio
            </a>
          </Typography>
          <Typography className={classes.description} variant="subtitle1">
            Discord:{" "}
            <a href="https://discord.gg/Ekd7UDd" target="_blank" rel="noopener noreferrer">
              https://discord.gg/Ekd7UDd
            </a>
          </Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button
              className={classes.button}
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              endIcon={<ArrowForwardIosIcon fontSize="small" />}
            >
              Start Browsing Pools
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default AboutPage;
